import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

import { strings } from "../../utils/localization";
import styled from "styled-components";

const DEFAULT_AU_MASK = "(..) .... ....";
const SHORT_AU_MASK = ".. .... ....";

const Container = styled.div`
  input {
    outline: none;
  }

  input:focus {
    outline: none !important;
  }
`;

const ModernPhoneNumberField = ({
  value,
  buttonStyle,
  onChange,
  defaultCountryFlag,
  getPhoneNumberFromClipboard,
  onFocus,
  onBlur,
  placeholder,
  borderRadius = 27,
  token,
}) => {
  const styles = {
    buttonStyle: {
      backgroundColor: "#fff",
      borderRadius: 12,
      border: 0,
      borderRight: 0,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      ...buttonStyle,
    },
  };
  const [fieldTouched, setFieldTouched] = useState(false);

  const getValue = () => {
    if (defaultCountryFlag?.toLowerCase() === "au" && !value && !fieldTouched) {
      return "+04";
    }
    return value;
  };

  const onChangeHandler = (value) => {
    setFieldTouched(true);
    if (defaultCountryFlag?.toLowerCase() === "au" && !value && !fieldTouched) {
      return onChange(`+04${value}`);
    }
    return onChange(value);
  };

  useEffect(() => {
    document.querySelectorAll(".matadot-live-chat-123789-phone-input")[0] &&
      document
        .querySelectorAll(".matadot-live-chat-123789-phone-input")[0]
        .addEventListener("paste", getPhoneNumberFromClipboard);
  }, []);

  const auMask =
    token === "8c6c92ccee38405b378616e404a2009e90022a8f"
      ? SHORT_AU_MASK
      : DEFAULT_AU_MASK;

  return (
    <Container>
      <PhoneInput
        country={defaultCountryFlag || "us"}
        inputClass="matadot-live-chat-123789-phone-input"
        onlyCountries={["us", "ca", "au"]}
        countryCodeEditable
        value={getValue()}
        buttonStyle={styles.buttonStyle}
        placeholder={placeholder || strings.MOBILE_PHONE}
        onChange={onChangeHandler}
        dropdownStyle={{
          bottom: 35,
          borderRadius: 4,
          backgroundColor: "red !important",
        }}
        inputStyle={{
          borderRadius: 12,
          border: "0px",
          backgroundColor: "#fff",
          width: "100%",
        }}
        containerStyle={{
          boxShadow: "none",
        }}
        disableDropdown
        disableCountryCode={defaultCountryFlag?.toLowerCase() === "au"}
        masks={{ au: auMask }}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </Container>
  );
};

export default ModernPhoneNumberField;
